import { FC } from 'react';
import { css, SystemProps } from '@storyofams/react-ui';

import { RichText as RichTextComponent } from '../../RichText';

interface RichTextProps extends SystemProps {
  text: any;
}

export const RichText: FC<RichTextProps> = ({ ...props }) => (
  <RichTextComponent maxWidth="764px" width="100%" {...props} />
);
