import { FC } from 'react';
import { Stack, Box, css, SystemProps } from '@storyofams/react-ui';

import {
  render,
  MARK_BOLD,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_OL,
  NODE_UL,
  NODE_LI,
} from 'storyblok-rich-text-react-renderer';
import { Heading } from '../Heading';
import { Text } from '../Text';

interface RichTextProps extends SystemProps {
  text: any;
  markResolvers?: any;
  nodeResolvers?: any;
}

export const RichText: FC<RichTextProps> = ({
  markResolvers = {},
  nodeResolvers = {},
  text,
  ...props
}) => {
  if (!text) {
    return null;
  }

  return (
    <Box css={css({ '> *:last-child': { mb: 0 } })} {...props}>
      {render(text, {
        markResolvers: {
          [MARK_BOLD]: (children) => <b>{children}</b>,
          [MARK_LINK]: (children, props) => {
            const { href, target } = props;
            return (
              <Box textDecoration="underline" as="span" color="primary">
                <a href={href} target={target} rel="noopener noreferrer">
                  {children}
                </a>
              </Box>
            );
          },
          ...markResolvers,
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, { level, id, ...rest }) => {
            return (
              <Heading
                as={`h${level}` as any}
                mb={level > 3 ? 1 : [2, 3]}
                variant={`h${level}` as any}
                id={id}
                {...rest}
              >
                {children}
              </Heading>
            );
          },
          [NODE_PARAGRAPH]: (children) => (
            <Text variant="m" as="p" mb={2}>
              {children}
            </Text>
          ),
          [NODE_OL]: (children) => (
            <Stack
              // @ts-ignore
              as="ol"
              pl={2}
              pb={[2, 3]}
              flexDirection="column"
            >
              {children}
            </Stack>
          ),
          [NODE_UL]: (children) => (
            <Stack
              // @ts-ignore
              as="ul"
              pl={2}
              pb={[2, 3]}
              flexDirection="column"
            >
              {children}
            </Stack>
          ),
          [NODE_LI]: (children) => (
            <Text as={'li' as any} css={css({ p: { pb: 0 } })}>
              {children}
            </Text>
          ),
          ...nodeResolvers,
        },
      })}
    </Box>
  );
};
